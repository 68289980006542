import React, { useEffect, useState } from "react";
import Heading2 from "../components/headings/Heading2";
import { Navigate } from "react-router-dom";

const Home = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const slug = localStorage.getItem("slug");
  const [welcomeStr] = useState("Welcome to ");
  const [displayWelcome, setDisplayWelcome] = useState("");
  const [showCMShead, setShowCMShead] = useState(false);
  if (user) {
    if (slug) {
      return <Navigate to={`/${slug}/view-organisation`} />;
    } else {
      return <Navigate to="/all-organisations" />;
    }
  }
  useEffect(() => {
    let tempStr = welcomeStr.split("");
    let displayStr = [];
    tempStr.forEach((char, index) => {
      setTimeout(() => {
        displayStr.push(char);
        setDisplayWelcome(displayStr.join(""));
      }, index * 100); // Incremental delay based on character index
    });

    setTimeout(() => {
      setShowCMShead(true);
    }, 100 * welcomeStr.length);

    return () => {
      tempStr = [];
    };
  }, []);
  return (
    <div align="center">
      <div className="cd-banner-content clearfix">
        <div className="cd-left-content cd-banner-item">
          <div className="cd-logo-img">
            <img src="/company-logo.jpg" alt="" />
          </div>
          <div className="cd-project-head">
            <Heading2>
              Code <span className="cd-highlight-text">CMS</span>
            </Heading2>
            <button className="cd-login-button cd-button-for-mobile">
              <a className="cd-common-anchor-tag" href="#login-content">
                Continue
              </a>
            </button>
          </div>
        </div>
        <div id="login-content" className="cd-right-content cd-banner-item">
          <Heading2>
            {displayWelcome}
            <div
              className={`${showCMShead ? "cd-show-cms-head" : "cd-hide-cms-head"}`}
              style={{ marginTop: "5px" }}
            >
              Code <span className="cd-highlight-text">CMS</span>
            </div>
          </Heading2>
          <h3 style={{ marginTop: "20px" }}>
            Manage your <span className="cd-highlight-text">organization</span> and <br />
            <span className="cd-highlight-text">tasks</span> like a pro!
          </h3>
          <h5 style={{ marginTop: "20px" }}>Please login to continue</h5>
          <button className="cd-login-button">
            <a className="cd-common-anchor-tag" href="/login">
              Login
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
