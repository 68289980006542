import React from "react";
import Heading2 from "../components/headings/Heading2";

const PageNotFound = () => {
  return (
    <div className="cd-banner-content clearfix">
      <div className="cd-left-content cd-banner-item">
        <div className="cd-logo-img">
          <img src="/company-logo.jpg" alt="" />
        </div>
        <div className="cd-project-head">
          <Heading2>
            Code <span className="cd-highlight-text">CMS</span>
          </Heading2>
          <button className="cd-login-button cd-button-for-mobile">
            <a className="cd-common-anchor-tag" href="#login-content">
              Continue
            </a>
          </button>
        </div>
      </div>
      <div id="login-content" className="cd-right-content cd-banner-item">
        <Heading2>
          <span className="cd-highlight-text">404</span>
        </Heading2>
        <Heading2>Page Not Found!</Heading2>
        <br />
        <a className="cd-common-anchor-tag" href="/">
          Back to home
        </a>
      </div>
    </div>
  );
};

export default PageNotFound;
